import { useQuery } from '@tanstack/vue-query'

export function useUser() {
  const userInfo = useState<Api.User.UserInfo | null>('user', () => null)

  const { data, refresh } = useRequest<Api.User.UserInfo>('/user/info', { key: 'userInfo', server: false })

  // const { data, refetch } = useQuery({
  //   queryKey: ['userInfo'],
  //   queryFn: getUserInfo,
  // })

  watch(data, () => {
    userInfo.value = data.value ?? null
  })

  return {
    userInfo,
    isLogin: computed(() => Boolean(userInfo.value?.id)),
    isUnexpired: computed(() => userInfo.value?.isExpired === 0),

    refetch: refresh,
  }
}
