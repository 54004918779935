export function useLoginDialog() {
  // Globa State
  const loginDialogState = useState('login-dialog', () => ({
    visible: false,

    /**
     * 登录成功后跳转地址
     */
    redirect: '',
  }))

  return {
    visible: computed(() => loginDialogState.value.visible),
    redirect: computed(() => loginDialogState.value.redirect),

    show: () => {
      loginDialogState.value.visible = true
    },
    hide: () => {
      loginDialogState.value.visible = false
    },
    redirectAfterLogin: (path: string) => {
      loginDialogState.value.redirect = path
    },
  }
}
